<template>
  <b-modal
    id="modal-from-metodoPago"
    v-model="modalMetodoPago"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label="* DESCRIPCION:"
              label-for="descripcionCategoria"
            >
              <validation-provider
                #default="{ errors }"
                name="Descripcion"
                rules="required|min:2"
              >
                <b-form-input
                  id="descripcionMarca"
                  v-model="form.descripcion"
                  @keyup.enter="validar()"
                  lazy-formatter
                  disabled
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.descripcion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row class="mb-0-5">
          <b-col cols="12" class="mb-1">
            <b-form-checkbox v-model="estado" name="check-button" switch inline>
              ACTIVO
            </b-form-checkbox>
            <!-- <validation-provider
              #default="{ errors }"
              name="Metodo de pago"
              rules=""
            >
              <b-form-group
                :state="errors.length > 0 ? false : null"
                label="* METODO PAGO:"
                label-cols="12"
                label-cols-lg="3"
              >
                <v-select
                  v-model="codigoMetodoPago"
                  class="style-chooser select-size-sm"
                  label="valor"
                  :clearable="false"
                  :options="metodoPagoItems"
                  @input="obtenerFormaPago"
                  :class="{ 'is-invalid': !!errors.length }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.metodo_pago"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </b-form-group>
            </validation-provider> -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import MetodoPagoServices from "../services/metodoPago";
import FacturacionServices from "@/modules/configuracion-sin/services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    vSelect,
    BFormCheckbox,
  },
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        descripcion: "",
        codigo_metodo_pago: "",
        metodo_pago: "",
        estado: 1,
      },
      estado: true,
      metodoPagoItems: [],
      codigoMetodoPago: "",
      required,
      submitted: false,
      errores: [],
      modalMetodoPago: false,
      estadoItems: [
        { text: "ACTIVO", value: 1 },
        { text: "INACTIVO", value: 0 },
      ],
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR METODO DE PAGO" : "NUEVO METODO DE PAGO";
    },
  },
  watch: {
    "form.descripcion": function () {
      this.errores.descripcion = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.form.estado = this.estado ? 1 : 0;
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.descripcion = "";
          this.form.codigo_metodo_pago = "";
          this.form.estado = 1;
          this.estado = true;
          this.form.metodo_pago = "";
          this.codigoMetodoPago = "";
          this.modalMetodoPago = true;
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.descripcion = data["descripcion"];
          this.form.codigo_metodo_pago = data["codigo_metodo_pago"];
          this.form.metodo_pago = data["metodo_pago"];
          this.form.estado = data["estado"];
          this.estado = data["estado"] == 1 ? true : false;
          // WILL
          // this.codigoMetodoPago = {
          //   codigo: `${data["codigo_metodo_pago"]}`,
          //   valor: `${data["codigo_metodo_pago"]} - ${data["metodo_pago"]}`,
          //   descripcion: data["metodo_pago"],
          // };
          this.modalMetodoPago = true;
          break;
        }
      }
      this.errores = [];
    },
    cerrarModal() {
      this.form.id = null;
      this.modalMetodoPago = false;
    },
    actualizarMetodoPago(item) {
      this.form.id = null;
      this.modalMetodoPago = false;
      this.$emit("actualizarMetodoPago", item);
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      try {
        const response = await MetodoPagoServices.store(this.form);
        this.actualizarMetodoPago(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await MetodoPagoServices.update(
          this.form.id,
          this.form
        );
        this.actualizarMetodoPago(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // METODO PAGO
    obtenerFormaPago(value) {
      if (value == null) {
        this.form.codigo_metodo_pago = null;
        this.form.metodo_pago = null;
      } else {
        this.form.codigo_metodo_pago = value.codigo;
        this.form.metodo_pago = value.descripcion;
      }
    },

    async getMetodosPago() {
      try {
        const response = await FacturacionServices.getMetodoPago();
        this.metodoPagoItems = response.data
          .map((g) => ({
            codigo: g.codigo,
            valor: g.codigo + " - " + g.descripcion,
            descripcion: g.descripcion,
          }))
          .sort((a, b) => a.codigo - b.codigo);
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
  },
  mounted() {
    // WILL
    // this.getMetodosPago();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>